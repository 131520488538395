<template>
  <div class="local-post-list" @scroll.passive="onScroll($event.srcElement, $refs.fixedElement.$el)">
    <screen-header
      class="local-post-list__header"
      :title="$t('gmbLocalPosts.title')"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
      ref="fixedElement"
    >
      <skeleton-icon v-if="isLoading" margin-left="16px" width="44px" height="44px" />
      <v-popover
        placement="bottom-end"
        :autoHide="true"
        container=".local-post-list__header"
        popoverInnerClass="popover-inner no-overflow"
      >
        <ui-button
          class="local-post-list__header__cta"
          button="primary"
          variant="data"
          icon="store"
          :label="currentAccount.accountName"
          :mobile-toggle="true"
          v-if="accounts.length > 1 && currentAccount"
        />
        <template slot="popover">
          <div class="local-post-list__header__popover">
            <ui-dropdown
              class="local-post-list__header__popover__dropdown"
              id="dropdown-gmb-acounts"
              v-model="modelAccount"
              :options="accounts"
              :placeholder="$t('gmbLocalPosts.accountSelector.placeholder')"
              :dropdown-label="$t('gmbLocalPosts.accountSelector.label')"
              label="accountName"
              track-by="name"
              :show-label="true"
            />
          </div>
        </template>
      </v-popover>
    </screen-header>
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <ui-data-search
              :element-fixed="elementFixed"
              :scroll-direction="scrollDirection"
              :is-mobile="isMobile"
              :is-tablet="isTablet"
              :is-loading="isLoading"
              :is-scrolling="isScrolling"
              view="gmbQa"
              ref="fixedElement"
              @search="onSearch"
              :with-search="true"
            />
            <div class="local-post-list__list">
              <ui-data-list
                :items="currentLocalPosts"
                :is-loading="isLoading || isUpdating"
                :is-scrolling="isScrolling"
                :skeleton-loop="currentLocalPosts.length"
                :pagination="currentLocalPostsPagination"
                :is-paginate="true"
                @nextPage="loadGmbLocalPosts(null, true, true, 1)"
                @prevPage="loadGmbLocalPosts(null, true, true, -1)"
              >
                <template v-slot:skeleton>
                  <div class="local-post-list__list__content">
                    <skeleton-line height="18px" width="80%" />
                    <skeleton-line height="15px" width="50%" margin-top="3px" />
                  </div>
                  <skeleton-icon margin-left="16px" />
                </template>
                <template v-slot:item="slotProps">
                  <div class="local-post-list__list__content">
                    <div class="local-post-list__list__content__head">
                      <div class="local-post-list__list__content__head__date">
                        {{ date(slotProps.item.createTime) }}
                      </div>
                      <div>{{ $t('GmblocalPost.list.state') + slotProps.item.state.toLowerCase() }}</div>
                    </div>

                    <div class="local-post-list__list__content__name">
                      {{ slotProps.item.locationName }}
                    </div>

                    <div
                      v-tooltip="{
                        placement: 'top',
                        trigger: 'click',
                        content: slotProps.item.summary,
                        offset: 3,
                      }"
                      class="local-post-list__list__content__text"
                    >
                      {{ slotProps.item.summary }}
                    </div>
                    <div class="local-post-list__list__content__type">
                      {{ $t('GmblocalPost.list.topicType') + slotProps.item.topicType }}
                    </div>
                  </div>
                </template>
              </ui-data-list>
            </div>
            <div class="local-post-list__table">
              <ui-data-table
                :columns="columns"
                :rows="currentLocalPosts"
                :total-rows="totalRows"
                :is-loading="isLoading || isUpdating"
                :with-pagination="true"
                :pagination="currentLocalPostsPagination"
                @onCellClick="goToLocalPost"
                @loadItems="loadGmbLocalPosts({ ...$event }, true)"
              >
                <template slot="data-table-row" slot-scope="{ column, row, formattedRow }">
                  <span>
                    <div class="local-post-list__table__block">
                      <div class="local-post-list__table__block__content">
                        <div v-if="column.field === 'createTime'" class="local-post-list__table__block__content__name">
                          {{ date(formattedRow[column.field]) }}
                        </div>
                        <div
                          v-else-if="column.field === 'summary'"
                          v-tooltip="{
                            placement: 'top',
                            trigger: 'hover',
                            content: formattedRow.summary,
                            offset: 3,
                          }"
                          class="local-post-list__table__block__content__text"
                        >
                          {{ formattedRow.summary }}
                        </div>
                        <div v-else class="local-post-list__table__block__content__name">
                          {{ formattedRow[column.field] }}
                        </div>
                      </div>
                    </div>
                  </span>
                </template>
              </ui-data-table>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { formatedDate } from '@/utils/date.util'
import { pascalCaseToUnderscore } from '@/utils/utilities.util'

export default {
  name: 'GmbLocalPostsList',
  components: {
    UiDataTable,
    UiDataList,
    UiDropdown,
    UiDataSearch,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    UiButton,
    SkeletonLine,
    SkeletonIcon,
  },
  mixins: [onScroll],
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      isScrolling: false,
      search: '',
    }
  },
  async created() {
    this.isLoading = true
    await this.getAccounts({})
  },
  watch: {
    '$route.params.name': {
      immediate: true,
      async handler() {
        if (this.$route.params.name) {
          await this.loadGmbLocalPosts()
        }
      },
    },
    search() {
      this.loadGmbLocalPosts()
    },
  },
  computed: {
    ...mapState({
      accounts: state => state.gmb.accounts,
      currentLocalPosts: state => state.localPost.currentLocalPosts?.data || [],
      currentLocalPostsPagination: state => state.localPost.currentLocalPosts?.pagination || { page: 1, nbPages: 1 },
    }),
    totalRows() {
      return parseInt(this.currentLocalPostsPagination.nbResults) || 0
    },
    currentAccount() {
      return this.accounts.find(account => account.name === this.currentAccountName)
    },
    currentAccountName() {
      return this.$route.params.name
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('gmbLocalPosts.breadcrumb'),
          route: {
            name: 'GmbLocalPostsList',
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('GmblocalPost.list.column.locationName'),
          field: 'locationName',
        },
        {
          label: this.$t('GmblocalPost.list.column.createTime'),
          field: 'createTime',
        },
        {
          label: this.$t('GmblocalPost.list.column.summary'),
          field: 'summary',
          sortable: false,
        },
        {
          label: this.$t('GmblocalPost.list.column.topicType'),
          field: 'topicType',
        },
        {
          label: this.$t('GmblocalPost.list.column.state'),
          field: 'state',
        },
      ]
    },
    modelAccount: {
      get() {
        return this.currentAccountName
      },
      async set({ name }) {
        this.$router.push({ ...this.$route, params: { ...this.$route.params, name } }).catch(() => {})
      },
    },
  },
  methods: {
    ...mapActions({
      loadLocalPosts: 'localPost/loadLocalPosts',
      getAccounts: 'gmb/getAccounts',
    }),
    onSearch(value) {
      this.search = value
    },
    async loadGmbLocalPosts(tableParams = null, isUpdating = false, mobile = false, pageEvent) {
      if (isUpdating) {
        this.isUpdating = true
      } else {
        this.isLoading = true
      }
      if (mobile) {
        document.body.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
        tableParams = {
          page: parseInt(this.currentLocalPostsPagination.page) + pageEvent,
        }
      }
      // Format sorting field locationName => location_name
      if (tableParams && typeof tableParams.sort?.field === 'string') {
        tableParams.sort.field = pascalCaseToUnderscore(tableParams.sort.field)
      }
      try {
        await this.loadLocalPosts({ account: this.currentAccountName, tableParams, search: this.search })
      } finally {
        this.isUpdating = false
        this.isLoading = false
      }
    },
    goToLocalPost({ row }) {
      const { account, location, name } = row
      this.$router.push({ name: 'GmbLocalPosts', params: { name: location, account, location, id: name } })
    },
    date(date) {
      return formatedDate(date, 'L', this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.local-post-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }
  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }
    &__popover {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }
      &__dropdown {
        margin-top: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__content {
      flex: 1;

      &__head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;
        font-size: $font-size-sm;

        &__date {
          font-weight: $font-weight-700;
        }
      }

      &__name {
        font-weight: $font-weight-700;
      }

      &__type {
        font-size: $font-size-sm;
      }

      &__text {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        margin-bottom: 1rem;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: calc(100% - 3rem);
    }

    &__button {
      display: flex;
      justify-content: center;
      padding: 1rem;
    }

    &__block {
      display: flex;
      align-items: center;
      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          font-weight: 600;
        }
        &__text {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-default;
          font-style: normal;
        }
      }
    }
  }
}
</style>
