<template>
  <div class="ui-data-list">
    <template v-if="isLoading && !isScrolling">
      <div class="ui-data-list__item" v-for="n in skeletonLoop" :key="`item-skeleton-${n}`">
        <slot name="skeleton"></slot>
      </div>
    </template>
    <template v-else-if="items.length">
      <div class="ui-data-list__item" v-for="(item, idx) in items" :key="`item-${idx}`" @click="onClick(item)">
        <slot name="item" :item="item" :index="idx"></slot>
      </div>
    </template>
    <template v-else>
      <div class="ui-data-list__no-data">
        {{ $t('common.label.emptyData') }}
      </div>
    </template>
    <div v-if="pagination && !isLoading && isPaginate" class="ui-data-list__pagination">
      <div
        class="ui-data-list__pagination__icons backoffice-icons"
        v-if="!prevPagesDisbaled"
        @click="$emit('prevPage', true)"
      >
        chevron_left
      </div>
      <span>{{ pagination.page }}</span>
      <div
        class="ui-data-list__pagination__icons backoffice-icons"
        v-if="!nextPagesDisbaled"
        @click="$emit('nextPage', false)"
      >
        chevron_right
      </div>
    </div>
    <div v-if="pagination && !isLoading && infiniteScroll && pagination.nbPages > 1" class="ui-data-list__actions">
      <v-btn
        rounded
        outlined
        color="primary"
        :loading="isScrolling"
        :disabled="pagination.page >= pagination.nbPages"
        @click="$emit('loadItems')"
      >
        {{ $t('common.button.showMoreResults') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDataList',
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    pagination: {
      type: Object,
      required: false,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPaginate: {
      type: Boolean,
      required: false,
      default: false,
    },
    infiniteScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    skeletonLoop: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  methods: {
    onClick(item) {
      this.$emit('click', item)
    },
  },
  computed: {
    prevPagesDisbaled() {
      return parseInt(this.pagination?.page) <= 1
    },
    nextPagesDisbaled() {
      return parseInt(this.pagination?.page) >= parseInt(this.pagination?.nbPages)
    },
  },
}
</script>
<style lang="scss" scoped>
.ui-data-list {
  &__item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    transition: color $transition-duration-default $transition-effect-default;
    border-bottom: 1px solid var(--border-color);
    background-color: var(--bg-color);
    padding: $gutter-mobile;
    min-height: 64px;

    @media (min-width: $screen-sm) {
      padding: $gutter-tablet;
    }
    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      color: $blue-dodger;
    }
  }

  &__no-data {
    padding: $gutter-mobile;
    text-align: center;

    @media (min-width: $screen-sm) {
      padding: $gutter-tablet;
    }
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: $font-size-md;
    &__icons {
      font-size: $font-size-xxxl;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $gutter-mobile;
    padding-bottom: calc(#{$gutter-mobile * 2} + #{$button-min-height});
  }
}
</style>
